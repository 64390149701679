<template lang="pug">
ClientOnly
  BaseForm(@submit='register')
    div(class='flex items-start')
      div(class='grow')
        BaseFormInputEmail(
          id='subscribe-email'
          v-model='email'
          name='email'
          :classes='{ input: "border-0 outline-none text rounded-r-none" }'
          :placeholder='placeholderText'
          type='email'
          validation='required'
        )
      div
        BaseButton(
          aria-label='Submit'
          class='rounded-l-none h-12'
          :class='buttonClass'
          :color='buttonColor'
          :has-custom-text-color='hasCustomTextColor'
          :is-waiting='activateSpinner'
          size='XSMALL'
          type='submit'
        )
          slot
            ChevronRightIcon(class='w-5 h-5 stroke-current')
</template>

<script setup lang="ts">
import ChevronRightIcon from '@/assets/chevronRight.svg?component'
const { $uiEvents } = useNuxtApp()

const {
  buttonColor = 'ACTION',
  buttonClass,
  formName = 'email-subscribe',
  hasCustomTextColor = false,
  placeholderText = 'Enter Email Address',
} = defineProps<{
  buttonColor: ButtonColor
  buttonClass: string | any[]
  formName: string
  hasCustomTextColor?: boolean
  placeholderText: string
}>()

const activateSpinner: Ref<boolean> = ref(false)
const email: Ref<string | null> = ref(null)

let timer: ReturnType<typeof setTimeout> | undefined

function register() {
  if (activateSpinner.value) clearTimeout(timer)
  activateSpinner.value = true
  timer = setTimeout(() => {
    $uiEvents.$emit('newsletterSubscribed', {
      formName: formName,
      email: email.value,
    })
    activateSpinner.value = false
    navigateTo('/newsletter-subscribe/')
  }, 500)
}
</script>
